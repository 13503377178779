import { defineStore } from 'pinia';
import { userApi } from '../../user/user-api';
import { User } from '../../user/user';

export const useUserStore = defineStore('userStore', {
    state: () => ({
        user: null,
    }),
    getters: {
        getUser(state) {
            return state.user;
        },
    },
    actions: {
        setUser(user) {
            this.user = user;
        },
        async logInUser(userData) {
            try {
                const loginRedirectUrl = await userApi.login(userData);

                // get new /me data after login
                const newUser = await this.fetchUser(true);

                return {
                    user: newUser,
                    redirectUrl: loginRedirectUrl,
                };
            } catch (error) {
                // TODO handle errors differently?
                return Promise.reject(error);
            }
        },
        async registerUser(userData) {
            try {
                await userApi.register(userData);

                this.setUser(userData);

                return userData;
            } catch (error) {
                // TODO handle errors differently?
                return Promise.reject(error);
            }
        },
        async fetchUser() {
            /** @type {MeData} */
            const newMe = await userApi.getMe();

            this.user = User.createFromMe(newMe);

            return this.user;
        },
    },
});

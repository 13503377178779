import { apiRequestManager } from '../api/api-request-manager';

class CsrfManager {
    /**
     * @param {String} tokenName
     *
     * @return {Promise<?String>}
     */
    #getCsrfToken(tokenName) {
        const url = `api/csrf/${tokenName}`;

        return apiRequestManager.get(url).then((response) => {
            let csrfToken = null;

            if (response?.data?.token) {
                csrfToken = response.data.token;
            }

            return Promise.resolve(csrfToken);
        });
    }

    getCsrfRegister() {
        return this.#getCsrfToken('register');
    }

    getCsrfLogin() {
        return this.#getCsrfToken('login');
    }

    getCsrfLogout() {
        return this.#getCsrfToken('logout');
    }

    getCsrfContactUs() {
        return this.#getCsrfToken('contact-us');
    }

    getCsrfUserProfile() {
        return this.#getCsrfToken('user-profile');
    }

    getCsrfPayment() {
        return this.#getCsrfToken('payment');
    }

    getCsrfCloseAccount() {
        return this.#getCsrfToken('close-account');
    }

    getCsrfCoupon() {
        return this.#getCsrfToken('coupon');
    }

    getCsrfBillingInformation() {
        return this.#getCsrfToken('billing-information');
    }

    getCsrfNotifications() {
        return this.#getCsrfToken('notifications');
    }

    getCsrfResendConfirmation() {
        return this.#getCsrfToken('resend-confirmation');
    }

    getCsrfRequestPasswordReset() {
        return this.#getCsrfToken('request-password-reset');
    }

    getCsrfPostApiKey() {
        return this.#getCsrfToken('post-api-key');
    }

    getCsrfDeleteApiKey() {
        return this.#getCsrfToken('delete-api-key');
    }

    getCsrfPatchApiKey() {
        return this.#getCsrfToken('patch-api-key');
    }

    getCsrfFeedback() {
        return this.#getCsrfToken('feedback');
    }

    getCsrfFeatureRequest() {
        return this.#getCsrfToken('feature-request');
    }

    getCsrfEmailNotification() {
        return this.#getCsrfToken('email-notification');
    }

    getCsrfPreset() {
        return this.#getCsrfToken('preset');
    }

    getCsrfCancelSubscription() {
        return this.#getCsrfToken('cancel-subscription');
    }

    getCsrfContactSales() {
        return this.#getCsrfToken('contact-sales');
    }

    getCsrfEventSignUp() {
        return this.#getCsrfToken('event-signup');
    }

    getCsrfEducationalClaim() {
        return this.#getCsrfToken('educational-claim');
    }

    getCsrfEducationalRequestConfirmationMail() {
        return this.#getCsrfToken('educational-request-confirmation-mail');
    }
}

if (!global.gCsrfManager) {
    global.gCsrfManager = new CsrfManager();
}

/** @type CsrfManager */
const csrfManager = global.gCsrfManager;

export { csrfManager };
